export function setApiHost (env) {
  switch (true) {
    case RegExp('contexuat', 'g').test(env):
      return 'http://contex-uat-api.us-east-1.elasticbeanstalk.com'
    case RegExp('localhost', 'g').test(env):
      return 'http://localhost:3000'
    case RegExp('contex', 'g').test(env):
      return 'https://contex-api.chst.io'
    default:
      return 'default'
  }
}


/**
 * Fetch a resource from CONTEX backend.
 * The response will be parsed into an object upon a successful response.
 * A normalized Error object will be thrown upon a bad response.
 *
 * @param {string} path - The absolute path of the url to fetch
 * @param {Object} opts - Options to pass onto fetch
 * @returns {Promise<Object>}
 * @throws {Error} `error.name` will reflect the error name given from the server.
 * */

export default function fetchAPI (path, opts) {
  const API_HOST = setApiHost(document.location.origin)

  const defaultOptions = {
    headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'x-session-token': opts.token }
  }

  return fetch(`${API_HOST}${path}`, Object.assign(defaultOptions, opts))
    .then((response) => {
      if (response.ok || response.status === 302) {
        if (response.headers.get('content-type').startsWith('application/json')) {
          return response.json().catch(() => {
          }) // add catch when parsing non-json content
        } else {
          return response.text()
        }
      } else {
        if (response.headers.get('content-type').startsWith('application/json')) {
          return response.json()
            .then(response => {
              const error = new Error(response.message)
              error.name = response.error
              throw error
            })
        } else {
          return response.text()
            .then(text => {
              throw new Error(text)
            })
        }
      }
    })
}

export function getIncidents (token) {
  return fetchAPI('/api/v1/incidents', { method: 'GET', headers: { 'x-session-token': token } })
}

export function getIncident (token, userID, incidentID) {
  return fetchAPI(`/api/v1/incidents/${incidentID}`, { method: 'GET', headers: { 'x-session-token': token } })
}

export function getFollowUps (token, incidentID) {
  return fetchAPI(`/api/v1/incidents/${incidentID}/followUps`, { method: 'GET', headers: { 'x-session-token': token } })
}

export function updateIncident (token, incidentID, body) {
  return fetchAPI(`/api/v1/incidents/${incidentID}`, {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: {
      'x-session-token': token,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
  })
}

export function createIncident (token, body) {
  return fetchAPI(`/api/v1/incidents`, {
    method: 'POST',
    body: JSON.stringify(body.formProps),
    headers: {
      'x-session-token': token,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
}

export function createFollowUp (token, formProps, incidentUuid) {
  const body = {
    reason: formProps.reason,
    returnToPlay: formProps.returnToPlay,
    closeInjury: formProps.closeInjury,
    incidentUuid
  }
  return fetchAPI(`/api/v1/followUps`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'x-session-token': token,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
}

export function getIncidentsByUserID (token, userID) {
  return fetchAPI(`/api/v1/users/${userID}/incidents`, { method: 'GET', headers: { 'x-session-token': token } })
}

export function getStudents (token) {
  return fetchAPI(`/api/v1/students`, { method: 'GET', headers: { 'x-session-token': token } })
}

export function getSession (email, password) {
  const body = { email, password }
  return fetchAPI('/api/v1/sessions', { method: 'POST', body: JSON.stringify(body) })
}

export function getSessionFromToken (token) {
  return fetchAPI('/api/v1/sessions', { method: 'GET', headers: { 'x-session-token': token } })
}

export function destroySession (token) {
  return fetchAPI('/api/v1/sessions', { method: 'DELETE', headers: { 'x-session-token': token } })
}

export function createAccount (email, password, confirmPassword, firstName, lastName, code) {
  const body = { email, password, confirmPassword, firstName, lastName }
  return fetchAPI(`/api/v1/invitations/${code}`, {
    method: 'POST', body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
}

export function createAdmin (token, accountUuid) {
  const body = { accountUuid }
  return fetchAPI(`/api/v1/admins`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
}

export function createSchool (token, body) {
  return fetchAPI(`/api/v1/schools`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'x-session-token': token,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
}

export function inviteUser (email, creatorUuid, token) {
  const body = { email, creatorUuid }
  return fetchAPI('/api/v1/invitations', {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'x-session-token': token,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
}

export function sendPasswordReset (token, accountUuid) {
  return fetchAPI(`/api/v1/accounts/${accountUuid}/sendResetPassword`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      headers: { 'x-session-token': token }
    }
  })
}

export function resetPassword (password, confirmPassword, email, resetToken) {
  const body = { email, password, confirmPassword, resetToken }
  return fetchAPI(`/api/v1/accounts/${email}/resetPassword`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }
  })
}

export function getAccount (token) {
  return fetchAPI(`/api/v1/accounts`, { method: 'GET', headers: { 'x-session-token': token } })
}

export function getAccounts (token) {
  return fetchAPI('/api/v1/admins/fetchAccounts', { method: 'GET', headers: { 'x-session-token': token } })
}

export function deleteAccount (uuid, token) {
  return fetchAPI(`/api/v1/admins/accounts/${uuid}`, {
    method: 'DELETE',
    headers: { 'x-session-token': token, Accept: 'application/json', 'Content-Type': 'application/json' }
  })
}

export function disableAccount (uuid, token) {
  return fetchAPI(`/api/v1/admins/accounts/${uuid}/disable`, {
    method: 'POST',
    headers: { 'x-session-token': token, Accept: 'application/json', 'Content-Type': 'application/json' }
  })
}

export function enableAccount (uuid, token) {
  return fetchAPI(`/api/v1/admins/accounts/${uuid}/enable`, {
    method: 'POST',
    headers: { 'x-session-token': token, Accept: 'application/json', 'Content-Type': 'application/json' }
  })
}

export function getAllSchools (token) {
  return fetchAPI(`/api/v1/schools`, { method: 'GET', headers: { 'x-session-token': token } })
}

export function getSchools (name, token) {
  return fetchAPI(`/api/v1/schools/search/${name}`, { method: 'GET', headers: { 'x-session-token': token } })
}

export function getSchool (token, uuid) {
  return fetchAPI(`/api/v1/schools/${uuid}`, { method: 'GET', headers: { 'x-session-token': token } })
}

export function fetchInvitations (token) {
  return fetchAPI(`/api/v1/invitations`, { method: 'GET', headers: { 'x-session-token': token } })
}

export function resendInvitation (uuid, token) {
  return fetchAPI(`/api/v1/invitations/${uuid}/resend`, {
    method: 'POST',
    headers: { 'x-session-token': token, Accept: 'application/json', 'Content-Type': 'application/json' }
  })
}

export function deleteInvitation (uuid, token) {
  return fetchAPI(`/api/v1/invitations/${uuid}`, {
    method: 'DELETE',
    headers: { 'x-session-token': token, Accept: 'application/json', 'Content-Type': 'application/json' }
  })
}
